import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import { useStaticQuery, graphql } from "gatsby";
import GallerySectionNew from "./GallerySection/GallerySectionNew";

import "./GallerySections.scss";

const GallerySections = ({ frontmatter, location: { hash } }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const data = useStaticQuery(graphql`
    query GallerySectionQuery {
      allFile(filter: { sourceInstanceName: { eq: "gallerySections" } }) {
        group(field: relativeDirectory) {
          fieldValue
          edges {
            node {
              id
              name
              dir
              modifiedTime
              relativeDirectory
              relativePath
              publicURL
            }
          }
        }
      }
    }
  `);

  const {
    allFile: { group: sections },
  } = data;

  if (!selectedCategory && sections && sections[0]) {
    setSelectedCategory(sections[0]);
  }

  const filteredSection = sections.filter(
    ({ fieldValue }) =>
      !fieldValue.includes("/thumbs") &&
      sections.some(({ fieldValue: currFieldValue }) => currFieldValue === `${fieldValue}/thumbs`),
  );

  const { galleryCategories: categories, header } = frontmatter;

  useEffect(() => {
    if (hash) {
      const formattedHash = hash.replace("#", "");
      const hashSection = filteredSection.find((section) => section.fieldValue === formattedHash);
      if (hashSection) {
        setSelectedCategory(hashSection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fieldValue, edges } = selectedCategory || {};

  // TODO: use === ${fieldValue}/thumbs when there are better quality images
  const thumbs = sections.find(({ fieldValue: currFieldValue }) => currFieldValue === fieldValue);

  return (
    // relativeDirectory: { regex: "/^((?!thumbs).)*$/" }

    <>
      <div className="gallery-headline pb-3 mb-2">
        <h1>{header}</h1>
      </div>
      <div className="sections-container px-2 pb-2">
        {(filteredSection || []).map((section) => {
          const sectionName = categories[section.fieldValue] || section.fieldValue;
          const selectedName = selectedCategory && (categories[fieldValue] || fieldValue);

          return (
            <button
              className={`btn btn-sm mx-1 mb-2 ${sectionName === selectedName ? "selected" : ""}`}
              key={sectionName}
              type="button"
              onClick={() => setSelectedCategory(section)}
            >
              {sectionName}
            </button>
          );
        })}
      </div>
      {fieldValue && edges && (
        <GallerySectionNew
          section={{ fieldValue: categories[fieldValue] || fieldValue, edges }}
          thumbs={thumbs}
          key={`section-${fieldValue}`}
        />
      )}
    </>
  );
};

GallerySections.propTypes = {
  frontmatter: PropTypes.object,
  location: PropTypes.object,
};

GallerySections.defaultProps = {
  frontmatter: null,
  location: {},
};

export default GallerySections;
