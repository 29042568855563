import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Footer from "views/Footer";
import SEO from "components/SEO";
import LanguageSelector from "components/LanguageSelector/LanguageSelector";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";

import "../style/main.scss";
import GallerySections from "components/GallerySections/GallerySections";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query GalleryQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          galleryCategories {
            al_dograma
            pvc_dograma
            novi_obekti
            sekcionni_vrati
            sennici
            vanshni_shtori
            vert_shtori
            ohranitelni_roletki
            rolo_shtori
            interiorni_vrati
            vhodni_vrati
          }
          brand
          anchor
          content
          copyright
          header
          email
          imageFileName
          jumpToAnchor
          jumpToAnchorText
          menuText
          pages {
            gallery
            home
            aluminiumWindows
            products
            pvcWindows
            frontDoors
            verticalBlinds
            rollerBlinds
            externalBlinds
            securityShutters
            sectionalDoors
            interiorDoors
            sunshades
          }
          privacyHref
          privacyText
          social {
            facebook
            github
            linkedin
            medium
            twitter
          }
          subheader
          telephone
          termsHref
          termsText
          title
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const GalleryPage = ({ data, pathContext: { langKey, defaultLang, langTextMap }, location }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const { navBarNode, anchors, footerNode, gallery } = breakDownAllNodes(nodes);

  let langSelectorPart;
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap} />
    );
  }

  return (
    <>
      <SEO lang={langKey} title="Галерия" keywords={keywords} description={description} />
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
        extraItems={langSelectorPart}
        pathContext={{ langKey, defaultLang }}
      />
      <div className="bg-light pb-3">
        <GallerySections frontmatter={gallery.frontmatter} location={location} />
      </div>
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
  location: PropTypes.object,
};

GalleryPage.defaultProps = {
  pathContext: {
    langKey: "bg",
    defaultLang: "bg",
    langTextMap: {
      en: "English",
      bg: "Български",
    },
  },
  location: null,
};

export default GalleryPage;
