import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Carousel.scss";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";

const Carousel = (props) => {
  const carouselRef = useRef();
  const [slideLeftDisabled, setSlideLeftDisabled] = useState(true);
  const [slideRightDisabled, setSlideRightDisabled] = useState(true);

  const slideRightActive = () => {
    return carouselRef.current
      ? Math.abs(
          carouselRef.current.scrollLeft +
            carouselRef.current.offsetWidth -
            carouselRef.current.scrollWidth,
        ) > 3
      : false;
  };

  const slideLeftActive = () => {
    return carouselRef.current ? carouselRef.current.scrollLeft > 3 : false;
  };

  const setButtonsState = useCallback(() => {
    setSlideRightDisabled(!slideRightActive());
    setSlideLeftDisabled(!slideLeftActive());
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setButtonsState);

    return () => {
      window.removeEventListener("resize", setButtonsState);
    };
  }, [setButtonsState]);

  useEffect(() => {
    setButtonsState();
  }, [props, setButtonsState]);

  const setCarouselRef = (ref) => {
    carouselRef.current = ref;
    if (props.setref) {
      props.setref(ref);
    }
  };

  const slideLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= props.step || 100;
      if (carouselRef.current.scrollLeft <= 0) {
        setSlideLeftDisabled(true);
      }
      if (slideRightDisabled && slideRightActive()) {
        setSlideRightDisabled(false);
      }
    }
  };

  const slideRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += props.step || 100;
      if (
        Math.abs(
          carouselRef.current.scrollLeft +
            carouselRef.current.offsetWidth -
            carouselRef.current.scrollWidth,
        ) < 3
      ) {
        setSlideRightDisabled(true);
      }
      if (slideLeftDisabled && slideLeftActive()) {
        setSlideLeftDisabled(false);
      }
    }
  };

  const scrollHandler = (e) => {
    if (typeof props.onScroll === "function") {
      props.onScroll(e);
    }
    setButtonsState();
  };

  const { children, className, onScroll, ...other } = props;

  return (
    <div className={`ui-carousel ${className || ""}`} {...other}>
      <div
        className="carousel-content"
        ref={setCarouselRef}
        onScroll={onScroll ? scrollHandler : null}
      >
        {children}
      </div>
      <button
        className="carousel-nav carousel-left-nav"
        type="button"
        onClick={slideLeft}
        disabled={slideLeftDisabled}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button
        className="carousel-nav carousel-right-nav"
        type="button"
        onClick={slideRight}
        disabled={slideRightDisabled}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

Carousel.propTypes = {
  setref: PropTypes.func,
  onScroll: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  step: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Carousel.defaultProps = {
  setref: null,
  onScroll: null,
  step: 100,
  className: "",
};

export default Carousel;
