import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Carousel from "components/Carousel/Carousel";
import useForceUpdate from "hooks/useForceUpdate";

import "./GallerySection.scss";

const GallerySectionNew = ({ section, thumbs }) => {
  // const [activeIndex, setActiveIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [shownImages, setShownImages] = useState(10);
  const imageContainerRef = useRef(null);
  const handleCloseModal = () => setSelectedImage(null);
  const forceUpdate = useForceUpdate();

  // const handleSelect = (selectedIndex) => {
  //   carouselRef.current.slickGoTo(selectedIndex);
  // };

  const { fieldValue, edges } = section;

  const thumbClickListener = (thumbUrl) => {
    const url = thumbUrl.replace("/thumb", "");
    const imageUrls = edges.map((edge) => edge.node.publicURL);
    if (imageUrls.includes(url)) {
      setSelectedImage(url);
    }
  };

  const imagesScrollHandler = (e) => {
    const scrollableElement = e.target;
    if (
      (scrollableElement &&
        scrollableElement.scrollWidth -
          scrollableElement.scrollLeft -
          scrollableElement.offsetWidth >=
          20) ||
      shownImages >= thumbs.edges.length
    )
      return;
    setShownImages((currShownImages) => Math.min(thumbs.edges.length, currShownImages + 10));
  };

  const isLastShownThumb = (index) =>
    index === shownImages - 1 || index === thumbs.edges.length - 1;

  const carouselItems = ((thumbs && thumbs.edges) || [])
    .slice(0, shownImages)
    .map(({ node: thumb }, thumbIndex) => {
      return (
        <div
          className="px-1 image-wrapper"
          style={{ outline: "none" }}
          onClick={thumbClickListener.bind(this, thumb.publicURL)}
          role="button"
          key={thumb.id}
          // role="button"
          tabIndex={0}
        >
          <img
            style={{ height: "200px", width: "auto" }}
            src={thumb.publicURL}
            alt={thumb.relativeDirectory}
            onLoad={isLastShownThumb(thumbIndex) ? forceUpdate : null}
          />
        </div>
      );
    });

  return (
    <>
      <section className="gallery-section icon-text pb-4 px-2 mx-2 pt-2" key={fieldValue}>
        <h3 className="w-100 text-center">{fieldValue}</h3>
        <div className="thumbs w-100 d-flex mt-3 px-3" ref={imageContainerRef}>
          <Carousel className="w-100" onScroll={imagesScrollHandler}>
            {carouselItems}
          </Carousel>
        </div>
      </section>
      <Modal show={!!selectedImage} onHide={handleCloseModal}>
        <Modal.Header closeButton />
        <Modal.Body>
          <img src={selectedImage} alt="selected" style={{ height: "auto", width: "100%" }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

GallerySectionNew.propTypes = {
  section: PropTypes.object.isRequired,
  thumbs: PropTypes.object,
};

GallerySectionNew.defaultProps = { thumbs: null };

export default GallerySectionNew;
